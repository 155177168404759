export default class Navigation {
  constructor() {
    this.body = $("body");
    this.mainNavContainer = $(".navigation-container");
    this.menu = $(".side-menu");
    this.submenuContainer = $(".submenu-container");
    this.navButtons = this.mainNavContainer.find(".nav-button");
    this.backProductNav = this.mainNavContainer.find(".backProductNav");
    this.backProductBtn = this.mainNavContainer.find(".product-button");
    this.closeButton = this.submenuContainer.find(".submenu-close-icon");
    this.mobileButtons = this.mainNavContainer.find(".mobile-menu-button");
    this.activeNavButton = null;
    this.backButtons = this.mainNavContainer.find(
      ".side-menu .products-back-buttons .back-button",
    );

    this.navButtons.on("click", (event) => {
      this.OnNavButtonClick(event);
    });

    this.closeButton.on("click", () => {
      this.HideSubmenu();
    });

    this.mobileButtons.on("click", (event) => {
      const button = $(event.currentTarget);
      const state =
        button.attr("data-state") === "closed" ? "opened" : "closed";
      button.attr("data-state", state);
      this.mainNavContainer.attr("data-mobile-state", state);
      this.body.toggleClass("no-scroll", state === "opened");
    });
    this.CheckLastProductVisited();
  }

  CheckLastProductVisited() {
    const currentProduct = this.mainNavContainer.attr("data-product");
    const lastSavedVisitedProduct = localStorage.getItem("lastVisitedProduct");

    //When going on a product page, save as a temporary cookie
    if (currentProduct === "TROUBADOUR" || currentProduct === "CONSTELLATION") {
      this.SetProductTempCookie("NanomonxVisitedProduct", currentProduct, 1);
    }

    //When on admin page, check if we come from a previous product subdomain
    if (currentProduct === "NANOMONX") {
      const visitedProductCookie = this.GetLastVisitedProductCookie();

      //If we have a cookie and it is not saved, save to localstorage
      if (
        visitedProductCookie &&
        lastSavedVisitedProduct !== visitedProductCookie
      ) {
        localStorage.setItem("lastVisitedProduct", visitedProductCookie);
        this.ShowBackButton(visitedProductCookie);
      } else if (lastSavedVisitedProduct) {
        this.ShowBackButton(lastSavedVisitedProduct);
      }
    }
  }

  SetProductTempCookie(cookieName, cookieValue, durationInDays) {
    //Format cookie expiration
    const dt = new Date();
    dt.setTime(dt.getTime() + durationInDays * 24 * 60 * 60 * 1000);
    const expires = `expires=${dt.toGMTString()}`;

    //Get domain without subdomain
    const domainParts = location.hostname.split(".");
    const domain = `domain=${domainParts.slice(-2).join(".")}`;

    document.cookie = `${cookieName}=${cookieValue}; ${expires}; ${domain}`;
  }

  GetLastVisitedProductCookie() {
    const unprocessedCookieArray = document.cookie.split("; ");
    const cookieObj = {};

    for (const element of unprocessedCookieArray) {
      const tempElement = element.split("=");
      cookieObj[tempElement[0]] = tempElement[1];
    }

    return cookieObj["NanomonxVisitedProduct"];
  }

  ShowBackButton(product) {
    const backProductNavItem = this.backProductNav.filter(
      `[data-back-product="${product}"]`,
    );

    // Show back product sidemenu
    backProductNavItem.removeClass("hide");
  }

  OnNavButtonClick(event) {
    const selectedButton = $(event.currentTarget);
    const id = selectedButton.attr("id");

    this.navButtons.removeClass("submenu-arrow");

    if (selectedButton.is(this.activeNavButton)) {
      this.HideSubmenu();
    } else {
      this.activeNavButton = selectedButton;
      if (id !== undefined && id !== null) {
        this.ShowSubmenu(id);
      }
    }
  }

  ShowSubmenu(id) {
    const submenus = this.submenuContainer.find(".submenu");
    const submenu = submenus.filter(`[data-link-id="${id}"]`);
    const openedSubmenuContainer = submenu.closest(".submenu-container");

    this.activeNavButton.addClass("submenu-arrow");

    submenus.removeClass("active");
    this.submenuContainer.removeClass("opened");

    openedSubmenuContainer.addClass("opened");
    submenu.addClass("active");

    $(document).on("click.outsideSubmenu", (e) => {
      if (!this.menu.is(e.target) && this.menu.has(e.target).length === 0) {
        this.HideSubmenu();
        $(document).off("click.outsideSubmenu");
      }
    });
  }

  HideSubmenu() {
    this.submenuContainer.find(".submenu").removeClass("active");
    this.submenuContainer.removeClass("opened");
    if (this.activeNavButton) {
      this.activeNavButton.removeClass("submenu-arrow");
      this.activeNavButton = null;
    }
  }
}
